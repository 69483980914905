import React, { useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
// import Modal from '@internet-boutique-engineering/site-ui/src/themes/bosco-new/components/modal';
import getPopmechanicEmbed from '../../../core/configuration/selectors/get-popmechanic-embed';
import getPopmechanicUrl from '../../../core/configuration/selectors/get-popmechanic-url';
import userSelectors from '../../../core/user/selectors';
import styles from './styles';
import setAppStatusBusy from '../../common/actions/set-app-status-busy';
import fetchCart from '../../cart/operations/fetch-cart';
import cartActions from '../../cart/actions';
import setAppStatusIdle from '../../common/actions/set-app-status-idle';
import favorites from '../../favorites';
import userHooks from '../../../core/user/hooks';

const useStyles = createUseStyles(styles);

const RecommendationContainerArticoli = () => {
  const classes = useStyles();
  const popmechanicEmbed = useSelector(getPopmechanicEmbed);
  const popmechanicUrl = useSelector(getPopmechanicUrl);
  const { bxSmSaleId } = useSelector(userSelectors.getUser);

  const { isAuthentificated } = userHooks.useUser();

  const initPopmechanic = useCallback(() => {
    const script = document.createElement('script');
    script.src = popmechanicUrl;

    document.body.appendChild(script);
  }, [popmechanicUrl]);

  // const [quickViewSRC, setQuickViewSRC] = useState(null);
  // const [showQuickView, setShowQuickViev] = useState(false);

  useEffect(() => {
    initPopmechanic();
  }, []);

  const dispatch = useDispatch();

  const addRef = useRef();

  const globalBlock = addRef.current;

  if (globalBlock) {
    setTimeout(() => {
      const addToCartBlock = globalBlock.querySelectorAll('.product-list-add-to-cart-js');
      const addToFavorite = globalBlock.querySelectorAll('.favorite-action-link-js');
      const quickView = globalBlock.querySelectorAll('.quick-view');

      if (addToCartBlock && addToCartBlock.length) {
        addToCartBlock.forEach((el) => {
          el.addEventListener('click', () => {
            const formData = new FormData();

            const dataSetArr = Object.keys(el.dataset);

            dataSetArr.forEach((item) => {
              formData.append(item, el.dataset[item]);
            });

            try {
              dispatch(setAppStatusBusy());
              fetch('/personal/cart/add-parent-to-cart.php', {
                method: 'POST',
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                },
                body: formData,
              })
                .then((res) => res.json())
                .then((response) => {
                  if (response.success) {
                    dispatch(setAppStatusBusy());
                    fetchCart(bxSmSaleId).then((data) => {
                      dispatch(cartActions.setCart(data));
                      dispatch(setAppStatusIdle());
                    });
                  }
                });
            } catch (error) {
              dispatch(setAppStatusIdle());
              console.log(error);
            }
          });
        });
      }
      if (addToFavorite && addToFavorite.length && isAuthentificated) {
        addToFavorite.forEach((el) => {
          // eslint-disable-next-line no-param-reassign
          el.style.display = 'block';

          el.addEventListener('click', () => {
            const dataHref = el.dataset.href;

            try {
              dispatch(setAppStatusBusy());
              fetch(dataHref, {
                method: 'GET',
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                },
              })
                .then((res) => res.json())
                .then((response) => {
                  if (response.success) {
                    dispatch(setAppStatusBusy());
                    favorites.operations
                      .fetchFavorites()
                      .then(({ data: favoritesData }) => {
                        const favoritesAction = favorites.actions.setFavorites(
                          favoritesData
                        );
                        dispatch(favoritesAction);
                      });
                    fetchCart(bxSmSaleId).then((data) => {
                      dispatch(cartActions.setCart(data));
                      // eslint-disable-next-line no-param-reassign
                      el.style.display = 'none';
                      dispatch(setAppStatusIdle());
                    });
                  }
                });
            } catch (error) {
              dispatch(setAppStatusIdle());
              console.log(error);
            }
          });
        });
      }
      if (quickView && quickView.length) {
        quickView.forEach((el) => {
          el.addEventListener('click', () => {
            // setShowQuickViev(true);
            console.log('----------', el.dataset.href);
            const dataHref = el.dataset.href;
            // setQuickViewSRC(dataHref);
            try {
              dispatch(setAppStatusBusy());
              fetch(dataHref, {
                method: 'GET',
                headers: {
                  'X-Requested-With': 'XMLHttpRequest',
                  'Content-Type': 'text/html; charset=UTF-8',
                },
              })
                .then((res) => res)
                .then((response) => {
                  console.log(response.body);
                });
            } catch (error) {
              dispatch(setAppStatusIdle());
              console.log(error);
            }
          });
        });
      }
    }, 50);
  }

  return (
    <div
      ref={addRef}
      className={classes.widget}
      data-popmechanic-embed={popmechanicEmbed}
    >
      {/* {quickViewSRC && showQuickView && (
        <Modal className={classes.modal} close={() => setShowQuickViev(false)}>
          <iframe src={quickViewSRC} width="100%"></iframe>
        </Modal>
      )} */}
      <div style={{ display: 'flex', justifyContent: 'left' }}>
        <div
          className="popmechanic-item-card-wrapper tns-item tns-slide-active"
          style={{ position: 'relative', overflow: 'hidden' }}
          data-click-track="0"
          id="tns1-item0"
        >
          <div className="popmechanic-item-card">
            <div className="popmechanic-item-card__image">
              <a
                className="popmechanic-item-card__info"
                href="https://articoli.ru/product/parfyumernaya-voda-eyes-closed-50-ml-668237/?s=UNI"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://reco-imgproxy.mindbox.ru/ks-NSY0UAtEvX8Kcl1VfaGxXr38YYDCESZ_P0FXA2js/fit/600/600/ce/0/aHR0cHM6Ly9hcnRpY29saS5ydS91cGxvYWQvaWJsb2NrLzNhZC8zYWQ0YjQ4MTAyYmQyYTJkZWIyYmNjMmZkZGE1ODc1Zl82NzZfOTAwLmpwZw"
                  alt=""
                />
              </a>
            </div>
            <div className="popmechanic-item-card__name-block">
              <p className="popmechanic-item-card__brand">Byredo</p>
              <a className="popmechanic-item-card__info popmechanic-item-card__title">
                Парфюмерная вода Eyes Closed, 50 мл
              </a>
            </div>
            <div className="popmechanic-item-card__block-price">
              <div className="popmechanic-item-card__price">
                <span className="popmechanic-item-card__price-value">24&nbsp;750</span>
                <span className="popmechanic-item-card__price-currency"></span>
              </div>
            </div>
            <a
              rel="nofollow"
              className="favorite-action-link-js analitics-click-js catalog-item-favorite"
              data-href="/personal/favorite-action.php?ACTION=ADD&amp;PARENT=5058603"
              data-title-add="Добавить в избранное"
              data-title-remove="Убрать из избранного"
              data-type="dobavit_v_izbrannoye"
              style={{
                display: 'block',
                width: '40px',
                height: '40px',
                background: 'blue',
              }}
            >
              <span
                className="tooltip"
                style={{
                  left: 0,
                  margin: 0,
                  width: 'auto',
                  top: '-20px',
                  boxSizing: 'content-box',
                }}
              >
                <span
                  style={{ boxSizing: 'content-box' }}
                  className="tooltip-text tooltip-text-js"
                >
                  Добавить в избранное
                </span>
              </span>
            </a>

            <a
              rel="nofollow"
              href="/catalog/quick-view.php?ELEMENT_ID=7886857"
              className="quick-view js-fancy-ajax analitics-click-js js-gtm-quick-view-link"
              data-type="quick_view_target"
            >
              <span>Быстрый просмотр</span>
            </a>
            <div
              className="list-add-to-cart-block js-add-to-cart-quantity-block"
              style={{ width: '100%', height: '40px', background: 'red' }}
            >
              <a
                type="button"
                data-href="/personal/cart/add-parent-to-cart.php"
                rel="nofollow"
                className="product-list-add-to-cart-js
            add-to-cart-icon analitics-click-js js-gtm-to-cart-link js-gtm-simple"
                data-type="dobavit_korzinu"
                data-parent="7886857"
                data-offer="68382049"
                data-quantity="1"
                data-gtm-action="add"
                data-gtm-category="Conversions"
                data-gtm-categoryid="7886857"
                data-gtm-label="cart"
                style={{
                  display: 'block',
                  width: '40px',
                  height: '40px',
                  background: 'green',
                }}
              ></a>
            </div>
          </div>
        </div>
        <div
          className="popmechanic-item-card-wrapper tns-item"
          style={{ position: 'relative', overflow: 'hidden' }}
          data-click-track="14"
          aria-hidden="true"
          tabIndex="-1"
        >
          <div className="popmechanic-item-card">
            <div className="popmechanic-item-card__image">
              <a
                className="popmechanic-item-card__info"
                href="https://articoli.ru/product/parfyumernaya-voda-allegra-fiori-d-amore-50-ml-615215/?s=UNI"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://reco-imgproxy.mindbox.ru/3Z38KiOgr5TtRM4t2UxG-1RVAXilLckutGw2qts-b-Q/fit/600/600/ce/0/aHR0cHM6Ly9hcnRpY29saS5ydS91cGxvYWQvaWJsb2NrLzU2YS81NmExY2VlZGM3Zjc1NzY0Y2FhMWU2ZTBlNjFkOGJmOV82NzZfOTAwLmpwZw"
                  alt=""
                />
              </a>
            </div>
            <div className="popmechanic-item-card__name-block">
              <p className="popmechanic-item-card__brand">BVLGARI</p>
              <a className="popmechanic-item-card__info popmechanic-item-card__title">
                {"Парфюмерная вода Allegra Fiori d'Amore, 50 мл"}
              </a>
            </div>
            <div className="popmechanic-item-card__block-price">
              <div className="popmechanic-item-card__price">
                <span className="popmechanic-item-card__price-value">16&nbsp;400</span>
                <span className="popmechanic-item-card__price-currency"></span>
              </div>
            </div>
            <div
              className="list-add-to-cart-block js-add-to-cart-quantity-block"
              style={{ width: '100%', height: '40px', background: 'red' }}
            >
              <a
                type="button"
                data-href="/personal/cart/add-parent-to-cart.php"
                rel="nofollow"
                className="product-list-add-to-cart-js add-to-cart-icon
                analitics-click-js js-gtm-to-cart-link js-gtm-simple"
                data-type="dobavit_korzinu"
                data-parent="6518419"
                data-offer="770691"
                data-quantity="1"
                data-gtm-action="add"
                data-gtm-category="Conversions"
                data-gtm-categoryid="6518419"
                data-gtm-label="cart"
                style={{
                  display: 'block',
                  width: '40px',
                  height: '40px',
                  background: 'green',
                }}
              ></a>
            </div>
            <a
              rel="nofollow"
              data-href="/catalog/quick-view.php?ELEMENT_ID=6518419"
              className="quick-view js-fancy-ajax analitics-click-js js-gtm-quick-view-link"
              data-type="quick_view_target"
              style={{
                display: 'block',
                width: '40px',
                height: '40px',
                background: 'gray',
              }}
            >
              <span>Быстрый просмотр</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendationContainerArticoli;
