import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { BoscoNewCartNavigation } from '@internet-boutique-engineering/site-ui';
import Main from '../../components/main';
import Aside from '../../components/aside';
import Layout from '../../components';
import Content from '../../components/content';

import ProgressContainer from '../../../modules/common/containers/progress-bar';
import CartContainer from '../../../modules/cart/containers';
import RecommendationContainer from '../../../modules/recommendations/containers';
import Loader from '../../../modules/loader/containers';
import Redirect from '../../../modules/redirect/containers';
import UserContainer from '../../../modules/user/containers';
import CheckoutContainer from '../../../modules/checkout/containers';
import TotalContainer from '../../../modules/checkout/containers/total';
import SubscribeContainer from '../../../modules/checkout/containers/subscribe';
import ModalsContainer from '../../../core/modals/containers';
import RegisterModals from '../../../core/modals/containers/register';

import getHeader from '../../../themes/get-header';

import userHooks from '../../../core/user/hooks';

const ThemeLayout = () => {
  const { isAuthentificated } = userHooks.useUser();
  const CartHeader = getHeader();

  return (
    <>
      <ProgressContainer />
      <ModalsContainer />
      <RegisterModals />
      <Router>
        <Layout>
          <CartHeader />
          <Switch>
            <Route path="/cart" exact>
              <BoscoNewCartNavigation Link={Link} />
              <Loader />
              <Content>
                <Main>
                  <CartContainer />
                  <RecommendationContainer />
                </Main>
                <Aside>
                  <TotalContainer />
                  <SubscribeContainer />
                </Aside>
              </Content>
            </Route>
            <Route path="/cart/personal">
              {isAuthentificated && (
                <>
                  <BoscoNewCartNavigation type="personal" Link={Link} />
                  <Loader />
                  <Content>
                    <UserContainer />
                  </Content>
                </>
              )}
              {isAuthentificated === false && <Redirect to="/personal/order/make/" />}
            </Route>
            <Route path="/cart/checkout">
              {isAuthentificated && (
                <>
                  <BoscoNewCartNavigation type="delivery" Link={Link} />
                  <Loader />
                  <Content>
                    <Main>
                      <CheckoutContainer hasSubscribe={false} />
                    </Main>
                    <Aside>
                      <TotalContainer isCheckout />
                      <SubscribeContainer />
                    </Aside>
                  </Content>
                </>
              )}
              {isAuthentificated === false && <Redirect to="/personal/order/make/" />}
            </Route>
          </Switch>
        </Layout>
      </Router>
    </>
  );
};

export default ThemeLayout;
