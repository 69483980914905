import React, { useState, useEffect, useCallback } from 'react';
import get from 'lodash.get';
import { useSelector, useDispatch } from 'react-redux';
import getDelivery from '../../../themes/get-delivery';
import checkout from '../../checkout';
import operations from '../operations';
import constants from '../constants';
import normalize from '../helpers/normalize';
import checkFreeDeliveryAvailable from '../helpers/is-free-delivery-available';
import minFreeDeliveryPrice from '../../../core/configuration/selectors/min-free-delivery-price';
import common from '../../common';
import CDEKWidget from '../../cdek-widget';

const { SELF_DELIVERY_ID_PREFIX } = constants;

const DeliveryContainer = () => {
  const formErrors = useSelector(checkout.selectors.getFormErrors);
  const deliveryError = get(
    formErrors,
    checkout.constants.formErrorTypes.DELIVERY_ERROR,
    null
  );
  const selectedLocationId = useSelector(checkout.selectors.getSelectedLocationId);
  const locationName = useSelector(checkout.selectors.getLocationName);

  const selectedDeliveryId = useSelector(checkout.selectors.getSelectedDeliveryId);
  const selectedDeliveryCost = useSelector(checkout.selectors.getSelectedDeliveryCost);
  const selectedStreet = useSelector(checkout.selectors.getSelectedStreet);
  const selectedDeliveryDetails = useSelector(
    checkout.selectors.getSelectedDeliveryDetails
  );
  const minFreeDelivery = useSelector(minFreeDeliveryPrice);
  const [deliveries, setDeliveries] = useState([]);
  const [showWidget, setShowWidget] = useState(false);
  const dispatch = useDispatch();
  const isFreeDeliveryAvailable = checkFreeDeliveryAvailable();

  useEffect(() => {
    if (!selectedLocationId) {
      setDeliveries([]);
      return;
    }

    dispatch(common.actions.setAppStatusBusy());
    operations
      .getDelivery(selectedLocationId, selectedStreet)
      .then((items) => {
        const deliveryItems = items.map((item) => {
          const { options, id } = item;
          const itemId = String(id);
          const isSelf = itemId.indexOf(SELF_DELIVERY_ID_PREFIX) !== -1;
          if (options && options.deliverySlots) {
            const slotId = get(selectedDeliveryDetails, 'deliverySlot.id', null);
            const slots = options.deliverySlots.map((slot) => ({
              ...slot,
              value: slot.id,
              isSelected: slotId === slot.id,
            }));
            return {
              ...item,
              isSelf,
              options: {
                ...options,
                deliverySlots: slots,
              },
            };
          }
          return {
            ...item,
            isSelf,
          };
        });
        setDeliveries(normalize(deliveryItems));
      })
      .finally(() => {
        dispatch(common.actions.setAppStatusIdle());
      });
  }, [selectedLocationId, selectedDeliveryDetails]);

  const onDelivery = useCallback(
    ({ value }) => {
      if (value === 'cdek:pvz' && !showWidget) {
        setShowWidget(true);
      } else {
        setShowWidget(false);
      }

      const selected = deliveries.find((delivery) => delivery.id === value);
      if (selected) {
        dispatch(checkout.actions.setDelivery(selected));
      }
    },
    [deliveries]
  );

  const deliveryTypeHandler = (e) => {
    if (e.target && e.target.value === 'cdek:pvz' && !showWidget) {
      setTimeout(() => {
        setShowWidget(true);
      }, 30);
    }
  };

  const onDeliveryDetails = useCallback((data) => {
    dispatch(checkout.actions.setDeliveryDetails(data));
  }, []);

  if (!selectedLocationId) return null;

  const Delivery = getDelivery();

  return (
    <div onClick={(e) => deliveryTypeHandler(e)}>
      <Delivery
        deliveryId={selectedDeliveryId}
        deliveryCost={selectedDeliveryCost}
        locationId={selectedLocationId}
        locationName={locationName}
        onDelivery={onDelivery}
        onDeliveryDetails={onDeliveryDetails}
        deliveries={deliveries}
        deliveryDetails={selectedDeliveryDetails}
        deliveryError={deliveryError}
        isFreeDeliveryAvailable={isFreeDeliveryAvailable}
        minFreeDeliveryPrice={minFreeDelivery}
      />
      {showWidget && <CDEKWidget setShowWidget={setShowWidget} />}
    </div>
  );
};

export default DeliveryContainer;
