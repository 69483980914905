/* eslint-disable react/display-name */
import get from 'lodash.get';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import checkout from '../../checkout';
import operations from '../operations';
import common from '../../common';

const PaySystemsContainer = (Component) => (props) => {
  const formErrors = useSelector(checkout.selectors.getFormErrors);
  const paySystemError = get(
    formErrors,
    checkout.constants.formErrorTypes.PAYSYSTEM_ERROR,
    null
  );
  const selectedDeliveryId = useSelector(checkout.selectors.getSelectedDeliveryId);
  const { variationId } =
    useSelector(checkout.selectors.getSelectedDeliveryDetails) || {};
  const selectedPaymentId = useSelector(checkout.selectors.getPaySystemId);
  const paymentPayload = useSelector(checkout.selectors.getPaySystemPayload);

  const dispatch = useDispatch();
  const [paySystems, setPaySystems] = useState([]);

  const onSelectPayment = useCallback(
    ({ value }) => {
      const selected = paySystems.find((item) => item.id === value);
      dispatch(checkout.actions.setPaySystem(selected));
    },
    [paySystems]
  );

  const onSelectPaymentPayload = useCallback((payload) => {
    dispatch(checkout.actions.updatePaySystemPayload(payload));
  });

  useEffect(() => {
    if (!selectedDeliveryId) return;

    dispatch(common.actions.setAppStatusBusy());
    operations.getPaysystems(variationId || selectedDeliveryId).then((list) => {
      dispatch(common.actions.setAppStatusIdle());

      // костыль для типа оплаты надо править на бэке

      const payType = [
        {
          id: '11',
          title: 'Банковской картой или СБП',
          description: '',
          onlinePayment: true,
        },
      ];
      if (list.length) {
        dispatch(checkout.actions.setPaySystemsList(list));
        setPaySystems(list);
      } else {
        dispatch(checkout.actions.setPaySystemsList(payType));
        setPaySystems(payType);
      }
    });
    //---------------------------------------------
  }, [selectedDeliveryId, variationId]);

  const wrapperRef = useRef(null);
  useEffect(() => {
    if (paySystemError && wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [paySystemError]);

  const scrollToError = () => {
    if (paySystemError && wrapperRef.current) {
      wrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  scrollToError();

  return (
    <Component
      {...props}
      paySystems={paySystems}
      onSelectPayment={onSelectPayment}
      selectedPaymentId={selectedPaymentId}
      paySystemError={paySystemError}
      onSelectPaymentPayload={onSelectPaymentPayload}
      paymentPayload={paymentPayload}
      wrapperRef={wrapperRef}
    />
  );
};

export default PaySystemsContainer;
