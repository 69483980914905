import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { TwoStateControl } from '@internet-boutique-engineering/site-ui';
import cartSelectors from '../../../cart/selectors';
import selectors from '../../selectors';
import actions from '../../actions';
import core from '../../../../core';
import styles from './styles';

import DiscountsContainer from '../../../discounts/containers';
import PromocodeContainer from '../../../promocodes/containers';
import UnauthorizedContainer from '../../../discounts/containers/unauthorized';
import userHooks from '../../../../core/user/hooks';
import groupBeSellers from '../../../cart/helpers/group-by-sellers';

const useStyles = createUseStyles(styles);

const {
  helpers: { price },
  configuration,
} = core;
const TotalContainer = ({ hiddenDelivery }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mainSeller = useSelector(configuration.selectors.getMainSeller);
  const isAllowBonuses = useSelector(configuration.selectors.getAllowBonusees);

  const [finalTotalPrice, setFinalTotalPrice] = useState(0);

  const amount = useSelector(selectors.getAmount);
  const isUseBonuses = useSelector(selectors.getUseBonuses);
  const cart = useSelector(cartSelectors.getCart);
  const cartBySellers = groupBeSellers(cart);

  const isMainSeller = (seller) => seller === mainSeller;

  const {
    cartPrice,
    discount,
    discountCard,
    discountPromoCode,
    bonuses,
    maxBonusesToUse,
    finalCartPrice,
    bonusToAdd,
    bonusToAddAfterDiscount,
  } = amount;

  const [changedBonusesValue, setChangedBonusesValue] = useState(0);

  const isBonusesAvailable = !!maxBonusesToUse && !!bonuses;

  const useBonuses = useCallback(
    ({ checked }) => {
      dispatch(actions.setUseBonuses(checked));
    },
    [maxBonusesToUse]
  );

  useEffect(() => {
    const totalCart = cartBySellers.reduce((acc, { seller, items }) => {
      const total = items.reduce(
        (sum, item) => sum + (item.price + (item.discount || 0)) * item.quantity,
        0
      );
      const productDiscount = items.reduce(
        (sum, item) => sum + (item.discount || 0) * item.quantity,
        0
      );
      const finalBonuses = isUseBonuses ? maxBonusesToUse : 0;

      const calcCartPrice = isMainSeller(seller)
        ? total - productDiscount - (discountPromoCode || 0)
        : total - productDiscount;

      const finalTotal = calcCartPrice - (isMainSeller(seller) ? finalBonuses : 0);
      return acc + finalTotal;
    }, 0);
    setFinalTotalPrice(totalCart);
  }, [cartBySellers, amount, hiddenDelivery, isUseBonuses]);

  useEffect(() => {
    if (isUseBonuses) {
      setChangedBonusesValue(bonusToAddAfterDiscount);
    } else {
      setChangedBonusesValue(bonusToAdd);
    }
  }, [isUseBonuses]);

  // useEffect(() => {
  //   if (isUseBonuses) {
  //     setChangedBonusesValue(bonusToAddAfterDiscount);
  //   } else {
  //     setChangedBonusesValue(bonusToAdd);
  //   }
  // }, []);

  const { isAuthentificated } = userHooks.useUser();

  return (
    <React.Fragment>
      {cartBySellers.map(({ seller, items }) => {
        const total = items.reduce(
          (sum, item) => sum + (item.price + (item.discount || 0)) * item.quantity,
          0
        );
        const productDiscount = items.reduce(
          (sum, item) => sum + (item.discount || 0) * item.quantity,
          0
        );
        const productCardDiscount = items.reduce(
          (sum, item) => sum + (item.cardDiscount || 0) * item.quantity,
          0
        );
        // const productCouponDiscount = items.reduce(
        //   (sum, item) => sum + (item.couponDiscount || 0) * item.quantity,
        //   0,
        // );
        const finalBonuses = isUseBonuses ? maxBonusesToUse : 0;

        const calcCartPrice = isMainSeller(seller)
          ? total - productDiscount - (discountPromoCode || 0)
          : total - productDiscount;

        const finalTotal = calcCartPrice - (isMainSeller(seller) ? finalBonuses : 0);

        return (
          <div
            key={seller}
            className={
              cartBySellers.length > 1 ? classes.sellerWrapper : classes.nonSellerWrapper
            }
          >
            {cartBySellers.length > 1 && (
              <h3 className={classes.sellerTitle}>{seller}</h3>
            )}
            {isMainSeller(seller) && (
              <React.Fragment>
                {isAuthentificated ? (
                  <React.Fragment>
                    <DiscountsContainer />
                    <PromocodeContainer />
                  </React.Fragment>
                ) : (
                  <UnauthorizedContainer />
                )}
              </React.Fragment>
            )}

            <div className={classes.wrapper}>
              <div
                className={
                  isMainSeller(seller) ? classes.cardTotal : classes.cardTotalSeller
                }
              >
                <span className={classes.label}>Стоимость товаров:</span>
                <span className={classes.value}>{price(total || cartPrice)}</span>
              </div>
              {isMainSeller(seller) && (
                <React.Fragment>
                  <div className={classes.row}>
                    <span className={classes.label}>Скидка:</span>
                    <span className={classes.value}>
                      {discount > 0 || productDiscount > 0 ? '-' : ''}
                      {price(productDiscount || discount || 0)}
                    </span>
                  </div>
                  {productCardDiscount !== 0 && (
                    <div className={classes.row}>
                      <span className={classes.label}>
                        Скидка по карте
                        {discountCard && (
                          <React.Fragment>- {discountCard.percent}%</React.Fragment>
                        )}
                        :
                      </span>
                      <span className={classes.value}>-{price(productCardDiscount)}</span>
                    </div>
                  )}
                  <div className={classes.row}>
                    <span className={classes.label}>Скидка по промокоду:</span>
                    <span className={classes.label}>
                      {discountPromoCode > 0 ? '-' : ''}
                      {price(discountPromoCode || 0)}
                    </span>
                  </div>
                </React.Fragment>
              )}

              <div
                className={
                  isMainSeller(seller)
                    ? classes.checkoutTotalGroup
                    : classes.checkoutTotalGroupSeller
                }
              >
                <span className={classes.label}>Всего к оплате:</span>
                <span className={classes.label}>
                  {price(finalTotal || finalCartPrice)}
                </span>
              </div>
              {isAllowBonuses && isMainSeller(seller) && (
                <React.Fragment>
                  <div className={classes.bonuse}>
                    <div className={classes.row}>
                      <span className={classes.label}>Бонусы на счету:</span>
                      <span className={classes.value}>{price(bonuses)}</span>
                    </div>
                    <span className={classes.legend}>
                      Оплата бонусами возможна при получении заказа
                    </span>
                  </div>
                  {Number(changedBonusesValue) > 0 && (
                    <div className={classes.bonuse}>
                      <div className={classes.row}>
                        <span className={classes.label}>Бонусы после покупки:</span>
                        <span className={classes.value}>
                          {price(changedBonusesValue)}
                        </span>
                      </div>
                    </div>
                  )}
                  {isBonusesAvailable && (
                    <div className={classes.useBonuses}>
                      <TwoStateControl
                        type="checkbox"
                        checked={isUseBonuses}
                        onChange={useBonuses}
                        name="useBonuses"
                        value={maxBonusesToUse}
                      >
                        <React.Fragment>
                          Использовать {price(maxBonusesToUse)} из {price(bonuses)}
                          бонусных баллов для оплаты до 50% от суммы заказа
                        </React.Fragment>
                      </TwoStateControl>
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        );
      })}
      <div className={classes.checkoutTotal}>
        <span className={classes.label}>Итого к оплате:</span>
        <span className={classes.label}>{price(finalTotalPrice || finalCartPrice)}</span>
      </div>
    </React.Fragment>
  );
};

TotalContainer.propTypes = {
  hiddenDelivery: PropTypes.bool,
};

TotalContainer.defaultProps = {
  hiddenDelivery: false,
};

export default TotalContainer;
