import get from 'lodash.get';
import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import throttle from 'lodash.throttle';
import getLocation from '../../../themes/get-location';
import selectors from '../selectors';
import actions from '../actions';
import checkout from '../../checkout';
import operations from '../operations';
import extractMoscow from '../helpers/extract-moscow';

const LocationContainer = () => {
  const formErrors = useSelector(checkout.selectors.getFormErrors);
  const locationError = get(
    formErrors,
    checkout.constants.formErrorTypes.LOCATION_ERROR,
    null
  );

  const countries = useSelector(selectors.getCountries);
  const selectedCountry = useSelector(checkout.selectors.getSelectedCountry);
  const selectedCity = useSelector(checkout.selectors.getSelectedCity);
  const selectedDistrict = useSelector(checkout.selectors.getSelectedDistrict);
  const selectedMetro = useSelector(checkout.selectors.getSelectedMetro);
  const selectedStreet = useSelector(checkout.selectors.getSelectedStreet);
  const selectedBuilding = useSelector(checkout.selectors.getSelectedBuilding);
  const selectedBlock = useSelector(checkout.selectors.getSelectedBlock);
  const selectedApartment = useSelector(checkout.selectors.getSelectedApartment);
  const selectedSavedLocation = useSelector(checkout.selectors.getSelectedSavedLocation);
  const selectedDelivery = useSelector(checkout.selectors.getSelectedDelivery);
  const cities = useSelector(selectors.getCities);
  const metroStations = useSelector(selectors.getMetroStations);
  const districts = useSelector(selectors.getDistricts);
  const [streetValue, setStreetValue] = useState(selectedStreet);
  const [building, setBuilding] = useState(selectedBuilding);

  useEffect(() => {
    setStreetValue(selectedStreet);
    setBuilding(selectedBuilding);
  }, [selectedStreet, selectedBuilding]);

  const dispatch = useDispatch();

  const [cladrCityId, setCladrCityId] = useState(null);
  const [streets, setStreets] = useState([]);

  const coutriesToRender = useMemo(
    () =>
      countries.map((country) => ({
        ...country,
        value: country.id,
        isSelected: selectedCountry && selectedCountry.value === country.id,
      })),
    [countries]
  );

  useEffect(() => {
    if (selectedCity && selectedCity.name) {
      const cladrCityName = selectedDistrict ? selectedDistrict.name : selectedCity.name;
      operations
        .getCityCladrId(extractMoscow(cladrCityName))
        .then((id) => setCladrCityId(id));
    }
    if (selectedCity && selectedCity.hasDistricts) {
      operations.getDistrics(selectedCity.id).then((items) => {
        dispatch(
          actions.setDistricts(
            items.map((district) => ({
              ...district,
              value: district.id,
            }))
          )
        );
      });
    }
    if (selectedCity && selectedCity.hasMetro) {
      operations.getMetro(selectedCity.id).then((stations) => {
        const stationsSorted = stations.sort((a, b) => (a.name > b.name ? 1 : -1));
        dispatch(
          actions.setMetroStations(
            stationsSorted.map((station) => ({
              ...station,
              value: station.id,
            }))
          )
        );
      });
    }
  }, []);

  useEffect(() => {
    if (countries.length && !selectedCountry) {
      const [firstCountry] = countries;
      dispatch(
        checkout.actions.setCountry({
          ...firstCountry,
          value: firstCountry.id,
        })
      );
    }
  }, [countries]);

  const onCountrySelected = useCallback((country) => {
    dispatch(checkout.actions.setCountry(country));
  }, []);

  const onCitySelected = useCallback((city) => {
    const { name, hasMetro, id, hasDistricts } = city || {};
    operations
      .getCityCladrId(extractMoscow(name))
      .then((currentId) => setCladrCityId(currentId));
    if (hasMetro) {
      operations.getMetro(id).then((stations) => {
        const stationsSorted = stations.sort((a, b) => (a.name > b.name ? 1 : -1));
        dispatch(
          actions.setMetroStations(
            stationsSorted.map((station) => ({
              ...station,
              value: station.id,
            }))
          )
        );
        dispatch(checkout.actions.setCity(city));
      });
    } else if (hasDistricts) {
      operations.getDistrics(id).then((items) => {
        dispatch(
          actions.setDistricts(
            items.map((district) => ({
              ...district,
              value: district.id,
            }))
          )
        );
        dispatch(checkout.actions.setCity(city));
      });
    } else {
      dispatch(checkout.actions.setCity(city));
    }
  }, []);

  const onMetroSelected = useCallback((metro) => {
    dispatch(checkout.actions.setMetro(metro));
  }, []);

  const onStreetSelected = useCallback((street) => {
    const { name } = street || {};
    dispatch(checkout.actions.setStreet(name));
  }, []);

  const onDistrictsSelected = useCallback((district) => {
    const { name } = district || {};
    operations.getCityCladrId(extractMoscow(name)).then((id) => setCladrCityId(id));
    dispatch(checkout.actions.setDistrict(district));
  }, []);

  const onCitySearch = throttle(
    useCallback(
      ({ value: currentValue }) => {
        operations.getCities(selectedCountry.id, currentValue).then((items) => {
          dispatch(actions.setCities(items));
        });
      },
      [selectedCountry]
    ),
    600,
    {
      leading: false,
    }
  );

  const onStreetSearch = throttle(
    useCallback(
      ({ value: currentValue }) => {
        if (!cladrCityId) {
          dispatch(checkout.actions.setStreet(currentValue));
          return;
        }

        operations
          .getStreetCladrSuggestions(currentValue, cladrCityId)
          .then((results) => {
            if (results && results.length) {
              setStreets(results);
            } else {
              dispatch(checkout.actions.setStreet(currentValue));
            }
          });
      },
      [cladrCityId]
    ),
    600,
    {
      leading: false,
    }
  );

  const onDistrictsSearch = throttle(
    useCallback(
      ({ value: currentValue }) => {
        operations.getDistrics(selectedCity.id, currentValue).then((item) => {
          dispatch(actions.setDistricts(item));
        });
      },
      [selectedCity]
    ),
    600,
    {
      leading: false,
    }
  );

  const onBuildingSet = useCallback((value) => {
    dispatch(checkout.actions.setBuilding(value));
  }, []);

  const onBlockSet = useCallback((value) => {
    dispatch(checkout.actions.setBlock(value));
  }, []);

  const onApartmentSet = useCallback((value) => {
    dispatch(checkout.actions.setApartment(value));
  }, []);

  const Location = getLocation();

  return (
    <Location
      key={selectedSavedLocation ? selectedSavedLocation.id : 'initial'}
      countries={coutriesToRender}
      onCountrySelected={onCountrySelected}
      selectedCountry={selectedCountry}
      selectedCity={selectedCity}
      selectedDistrict={selectedDistrict}
      selectedDelivery={selectedDelivery}
      onCitySearch={onCitySearch}
      onCitySelected={onCitySelected}
      cities={cities}
      cityDistricts={districts}
      selectedStreet={streetValue}
      selectedBuilding={building}
      selectedBlock={selectedBlock}
      selectedApartment={selectedApartment}
      metroStations={metroStations}
      selectedmetroStation={selectedMetro}
      onMetroSelected={onMetroSelected}
      onBuildingSet={onBuildingSet}
      onBlockSet={onBlockSet}
      onApartmentSet={onApartmentSet}
      onDistrictsSelected={onDistrictsSelected}
      onDistrictsSearch={onDistrictsSearch}
      streets={streets}
      onStreetSelected={onStreetSelected}
      onStreetSearch={onStreetSearch}
      locationError={locationError}
    />
  );
};

export default LocationContainer;
