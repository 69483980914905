const recommendationsStyles = ({
  extraLightColor,
  fontFamily,
  primaryTextColor,
  dropDownBorder,
  borderRadius,
  mobileMediaQuery,
}) => ({
  widget: {
    marginRight: 33,
  },
  addToFavoriteButtonShow: {
    display: 'block',
  },
  addToFavoriteButtonHide: {
    display: 'none',
  },
  modal: {
    backgroundColor: extraLightColor,
    fontFamily,
    position: 'fixed',
    zIndex: 999,
    top: '50%',
    left: '50%',
    color: primaryTextColor,
    borderColor: dropDownBorder,
    borderWidth: 10,
    borderStyle: 'solid',
    borderRadius,
    transform: 'translate(-50%, -50%)',
    padding: [30, 20, 0, 0],
    minWidth: '70%',
    minHeight: '70%',
    boxSizing: 'border-box',
    [`${mobileMediaQuery}`]: {
      minWidth: '100%',
    },
  },
});

export default recommendationsStyles;
