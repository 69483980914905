import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import getPopmechanicEmbed from '../../../core/configuration/selectors/get-popmechanic-embed';
import getPopmechanicUrl from '../../../core/configuration/selectors/get-popmechanic-url';
import styles from './styles';

const useStyles = createUseStyles(styles);

const RecommendationContainer = () => {
  const classes = useStyles();
  const popmechanicEmbed = useSelector(getPopmechanicEmbed);
  const popmechanicUrl = useSelector(getPopmechanicUrl);

  const initPopmechanic = useCallback(() => {
    const script = document.createElement('script');
    script.src = popmechanicUrl;

    document.body.appendChild(script);
  }, [popmechanicUrl]);

  useEffect(() => {
    initPopmechanic();
  }, []);

  return <div className={classes.widget} data-popmechanic-embed={popmechanicEmbed}></div>;
};

export default RecommendationContainer;
